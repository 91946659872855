import React from "react"
import { graphql } from "gatsby"
import Layout from "../modules/layout"
import SEO from "../modules/seo"
import BigHeader from "../components/bigHeader"
import GeneralContent from "../components/generalContent"
import Post from "../components/post"
import QuickSelect from "../components/quickSelect"

const Presse = ({ data }) => {
  const content = data.allContentfulLieblingsplatzBlog.nodes[0]

  return (
    <Layout theme="lieblingsplatz">
      <SEO title={content.title} />
      <BigHeader 
        image={content.headerImage.fluid} 
        alt={content.headerImage.description} 
        additionalClass="home"
        title={content.title}
        subTitle={content.subTitle}
      />
      <QuickSelect root={true} />
      <GeneralContent
        title={content.title}
        content={content.content ? content.content.json : null}
        textCentered={false}
      />
      {data.allContentfulPost.nodes.filter(item => item.node_locale === 'de' && item.type === 'Presse').map((item, index) => (
        <Post
          key={index}
          additionalClass="color_invert"
          title={item.postTitle}
          image={item.postImage}
          publishedAt={item.publishedAt}
          type={item.type}
          shortDesc={item.postShortDescription}
          postURLSlug={item.postURLSlug}
        />
      ))}
    </Layout>
  )
}

export const pageQuery = graphql`
query PresseQuery {
  allContentfulLieblingsplatzBlog(filter: {type: {eq: "Presse"}}) {
    nodes {
      content {
        content
      }
      id
      title
      subTitle
      headerImage {
        fluid(maxWidth: 1600 quality: 80) {
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
          aspectRatio
        }
        description
      }
    }
  }

  allContentfulPost {
    nodes {
      postTitle
      postShortDescription
      postContent {
        json
      }
      postURLSlug
      publishedAt
      node_locale
      postImage {
        fluid {
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
          aspectRatio
        }
      }
    }
  }
}
`

export default Presse
