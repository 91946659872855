import React from "react"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"
import moment from "moment"

const Post = ({ title, shortDesc, postURLSlug, type, publishedAt, style, image, additionalClass }) => (
  <section className={`component post py-5`} style={style}>
    <Container fluid className={`${additionalClass}`}>
      <div className="row">
        <div className="col-12 col-md-8">
          <h2 className="h3">{title}</h2>
          <span>{moment(publishedAt).format('DD MMM YYYY')}</span>
          <p className="mt-3">{shortDesc}</p>
          <Link to={`/${type === 'Blog' ? 'blog' : 'presse'}/${postURLSlug}`} className="btn btn-dark">Weiterlesen</Link>
        </div>
      </div>
    </Container>
  </section>
)

export default Post
